import { React, useState } from "react";
import RickMorty from "../data/Rick-and-Morty.png";

const Navbar = () => {
  const [navbar, setNavbar] = useState(false);
  return (
    <nav className="w-full bg-black bg-opacity-75  shadow fixed z-30 ">
      <div className="justify-between  md:items-center md:flex md:px-8">
        <div>
          <div className="flex items-center justify-between py-1 md:py-1 md:block">
            <a href="/">
              <img className="h-20 left-0" src={RickMorty} alt="Logo" />
            </a>
            <div className="md:hidden right-0 float-left">
              <button
                className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                onClick={() => setNavbar(!navbar)}
              >
                {navbar ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 text-white"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>
        <div>
          <div
            className={`flex-1  pb-3 mt-8 md:block md:pb-0 md:mt-0 ${
              navbar ? "block" : "hidden"
            }`}
          >
            <ul
              className="items-center  text-verdits font-mono text-2xl justify-center md:flex md:space-x-12 focus:ring-4 focus:outline-none focus:ring-verdits
"
            >
              <li className=" hover:text-indigo-200">
                <a href="/">Characters</a>
              </li>
              <li className=" hover:text-indigo-200">
                <a href="/aboutUs">About US</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
